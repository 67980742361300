
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
    };
  },
  data(){
    const settings = {};
    this.getProjectSettings();
    return {
    settings
  }
  },
  methods: {
    getProjectSettings() {
      store.dispatch(Actions.GET_PROJECT_SETTINGS)
          .then((res) => {
            this.settings = res.data;

          }).catch(() => {
          console.log('Settings not parsed')
      })

    }
  }
});
